import {
  JournalEntry,
  JournalEntryHelper,
  Product,
  Tag,
  Tags,
  useJournal,
} from "frontend-util";
import { TFunction } from "i18next";
import {
  Code,
  Empty,
  H3,
  Iccid,
  Imsi,
  List,
  Number,
  Underline,
} from "onway-ui";
import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { useCustomerKeyParam } from "../../customer/CustomerHooks";
import { useUserMetaDataStoreContext } from "../../user/UserMetaDataStore";
import { usePageSizeSetter } from "../../user/UserSettingsHooks";
import { Device } from "../device/Device";
import { DeviceHierarchy } from "../device/DeviceHierarchy";
import { useDevicesByIds } from "../device/DeviceHooks";
import { CurrentSimCardContext } from "./SimCard";
import { SimCardGateway } from "./SimCardGateway";

export const SimCardJournalView = (): ReactElement => {
  const { hasSimCardReadCodesPermission, productMap, simCard, tagsMap } =
    useOutletContext<CurrentSimCardContext>();
  const customerKey = useCustomerKeyParam();
  const { userMetaData } = useUserMetaDataStoreContext();
  const onSizeChange = usePageSizeSetter();
  const entityId: { id: string } = useMemo(
    () => ({
      id: simCard.iccid,
    }),
    [simCard],
  );
  const { requestState, entries, pagination } = useJournal<string>(
    SimCardGateway.journalUri(customerKey),
    entityId,
    onSizeChange,
    userMetaData.settings.pageSize,
  );
  const { t } = useTranslation();

  const journalDeviceIds = useMemo(() => {
    const deviceIds = new Set<number>();
    entries.forEach((entry) => {
      const oldDeviceId = entry.oldData.get("device_id");
      const newDeviceId = entry.newData.get("device_id");
      if (oldDeviceId !== undefined) {
        deviceIds.add(oldDeviceId);
      }
      if (newDeviceId !== undefined) {
        deviceIds.add(newDeviceId);
      }
    });
    return deviceIds;
  }, [entries]);

  const { devices } = useDevicesByIds(journalDeviceIds);
  const devicesMap: Map<number, Device> = new Map();
  devices.forEach((device) => devicesMap.set(device.id, device));

  const getAction = (entry: JournalEntry<string>) => {
    return JournalEntryHelper.getTranslatedJournalAction(t, entry.action);
  };

  return (
    <JournalEntryHelper.JournalPage
      entries={entries}
      loading={requestState.pending}
      pagination={pagination}
      renderDetails={(entry) =>
        JournalEntryHelper.renderDetails(
          entry,
          <JournalFieldDetails
            devicesMap={devicesMap}
            entry={entry}
            hasSimCardReadCodesPermission={hasSimCardReadCodesPermission}
            productMap={productMap}
            tagsMap={tagsMap}
            key="insert"
          />,
          <JournalChangedFieldDetails
            devicesMap={devicesMap}
            entry={entry}
            hasSimCardReadCodesPermission={hasSimCardReadCodesPermission}
            productMap={productMap}
            tagsMap={tagsMap}
            key="update"
          />,
        )
      }
      getAction={getAction}
    />
  );
};

const JournalFieldDetails = ({
  devicesMap,
  entry,
  hasSimCardReadCodesPermission,
  productMap,
  tagsMap,
}: {
  devicesMap: Map<number, Device>;
  entry: JournalEntry<string>;
  hasSimCardReadCodesPermission: boolean;
  productMap: Map<number, Product>;
  tagsMap: Map<number, Tag>;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <H3>
        <Underline>{t("fields")}</Underline>
      </H3>
      <List orientation="horizontal">
        <List wrapper="dl">{getIdentificationJournalFields(entry, t)}</List>
        <List wrapper="dl">
          {getSecurityJournalFields(entry, hasSimCardReadCodesPermission, t)}
        </List>
        <List wrapper="dl">
          {getDeviceJournalFields(devicesMap, entry, t, productMap)}
        </List>
        <List wrapper="dl">{getDetailsJournalFields(entry, t, tagsMap)}</List>
      </List>
    </>
  );
};

const JournalChangedFieldDetails = ({
  devicesMap,
  entry,
  hasSimCardReadCodesPermission,
  productMap,
  tagsMap,
}: {
  devicesMap: Map<number, Device>;
  entry: JournalEntry<string>;
  hasSimCardReadCodesPermission: boolean;
  productMap: Map<number, Product>;
  tagsMap: Map<number, Tag>;
}) => {
  const { t } = useTranslation();

  return (
    <JournalEntryHelper.JournalEntryTable title={t("changed_fields")}>
      {getIdentificationJournalFields(entry, t)}
      {getSecurityJournalFields(entry, hasSimCardReadCodesPermission, t)}
      {getDeviceJournalFields(devicesMap, entry, t, productMap)}
      {getDetailsJournalFields(entry, t, tagsMap)}
    </JournalEntryHelper.JournalEntryTable>
  );
};

const getIdentificationJournalFields = (
  entry: JournalEntry<string>,
  t: TFunction,
) => {
  return [
    <JournalEntryHelper.JournalField
      key="iccid"
      entry={entry}
      fieldLabel={t("iccid")}
      fieldName="iccid"
      renderValue={(value) => <Iccid copyable iccid={value} />}
    />,
    <JournalEntryHelper.JournalField
      key="imsi"
      entry={entry}
      fieldLabel={t("imsi")}
      fieldName="imsi"
      renderValue={(value) => <Imsi imsi={value} />}
    />,
    <JournalEntryHelper.JournalField
      key="msisdn"
      entry={entry}
      fieldLabel={t("msisdn")}
      fieldName="msisdn"
      renderValue={(value) => <Empty as={Number}>{value}</Empty>}
    />,
  ];
};

const getSecurityJournalFields = (
  entry: JournalEntry<string>,
  hasSimCardReadCodesPermission: boolean,
  t: TFunction,
) => {
  return [
    <JournalEntryHelper.JournalField
      key="pin-1"
      entry={entry}
      fieldLabel={t("pin1")}
      fieldName="pin_1"
      renderValue={(value) => {
        return hasSimCardReadCodesPermission ? (
          <Empty as={Code}>{value}</Empty>
        ) : (
          t("sim_card_read_codes_denied")
        );
      }}
    />,
    <JournalEntryHelper.JournalField
      key="pin-2"
      entry={entry}
      fieldLabel={t("pin2")}
      fieldName="pin_2"
      renderValue={(value) => {
        return hasSimCardReadCodesPermission ? (
          <Empty as={Code}>{value}</Empty>
        ) : (
          t("sim_card_read_codes_denied")
        );
      }}
    />,
    <JournalEntryHelper.JournalField
      key="puk-1"
      entry={entry}
      fieldLabel={t("puk1")}
      fieldName="puk_1"
      renderValue={(value) => {
        return hasSimCardReadCodesPermission ? (
          <Empty as={Code}>{value}</Empty>
        ) : (
          t("sim_card_read_codes_denied")
        );
      }}
    />,
    <JournalEntryHelper.JournalField
      key="puk-2"
      entry={entry}
      fieldLabel={t("puk2")}
      fieldName="puk_2"
      renderValue={(value) => {
        return hasSimCardReadCodesPermission ? (
          <Empty as={Code}>{value}</Empty>
        ) : (
          t("sim_card_read_codes_denied")
        );
      }}
    />,
  ];
};

const getDeviceJournalFields = (
  devicesMap: Map<number, Device>,
  entry: JournalEntry<string>,
  t: TFunction,
  productMap: Map<number, Product>,
) => {
  const renderDevice = (deviceId: number) => {
    const device = devicesMap.get(deviceId);
    const productNumber = device?.productNumber;
    const deviceSerial = device?.serial;
    return productNumber === undefined ? (
      <DeviceHierarchy deviceSerial={deviceSerial} productSku={productNumber} />
    ) : (
      <DeviceHierarchy
        deviceSerial={deviceSerial}
        productSku={productMap.get(productNumber)?.sku}
      />
    );
  };

  return [
    <JournalEntryHelper.JournalField
      key="device-id"
      entry={entry}
      fieldLabel={t("device")}
      fieldName="device_id"
      renderValue={(value) => renderDevice(value)}
    />,
    <JournalEntryHelper.JournalField
      key="slot"
      entry={entry}
      fieldLabel={t("sim_slot")}
      fieldName="slot"
      renderValue={(value) => <Empty as={Code}>{value}</Empty>}
    />,
  ];
};

const getDetailsJournalFields = (
  entry: JournalEntry<string>,
  t: TFunction,
  tagsMap: Map<number, Tag>,
) => {
  return [
    <JournalEntryHelper.JournalField
      entry={entry}
      fieldLabel={t("tags")}
      fieldName="tag_ids"
      key="tag_ids"
      renderValue={(value) => {
        if (value.length) {
          return <Tags tagsMap={tagsMap} tagIds={value} />;
        }
        return <Empty />;
      }}
    />,
    <JournalEntryHelper.JournalField
      entry={entry}
      fieldLabel={t("comment")}
      fieldName="comment"
      key="comment"
      renderValue={(value) => <Empty as="span">{value}</Empty>}
    />,
  ];
};
