import { NodeServerBox as NodeServerBoxComponent } from "frontend-util";
import { Code, Description, Empty, List, SafeOmit } from "onway-ui";
import { ComponentProps, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { isMppConnection } from "./mpp/MppConnection";

const NodeServerBox = (
  props: SafeOmit<
    ComponentProps<typeof NodeServerBoxComponent>,
    "horizontalContent"
  >,
): ReactElement => {
  const { connection } = props;
  const { t } = useTranslation();
  const mppInfo = isMppConnection(connection) ? (
    <List wrapper="dl">
      <Description term={t("system_id")}>
        <Code>{connection.systemId}</Code>
      </Description>
      <Description term={t("peer_system_id")}>
        <Code>{connection.peerSystemId}</Code>
      </Description>
      <Description term={t("federation_name")}>
        {connection.federationName === undefined ? (
          <Empty placeholder={"—"} />
        ) : (
          connection.federationName
        )}
      </Description>
    </List>
  ) : undefined;
  return <NodeServerBoxComponent {...props} horizontalContent={mppInfo} />;
};

export default NodeServerBox;
