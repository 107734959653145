import {
  RequestState,
  RequestStateSetter,
  useDelayedRequestState,
  useInterval,
} from "frontend-util";
import { followControlUrlParamName } from "onway-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SystemDeviceTelemetry } from "./SystemDeviceTelemetry";
import { SystemTelemetryGateway } from "./SystemTelemetryGateway";

const selectedSystemDeviceParamName = "device-id";

export const useSystemWidget = (
  customerKey: string,
): {
  selectedSystemDeviceTelemetry: SystemDeviceTelemetry | undefined;
  showDetails: (deviceId?: string) => void;
  systemDeviceTelemetryData: SystemDeviceTelemetry[];
  systemDeviceTelemetryRequestState: RequestState;
} => {
  const [systemDeviceTelemetryData, setSystemDeviceTelemetryData] = useState<
    Map<string, SystemDeviceTelemetry>
  >(new Map());
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedSystemDevice, setSelectedSystemDevice] = useState<
    string | null
  >(() => searchParams.get(selectedSystemDeviceParamName));
  const selectedSystemDeviceTelemetry = useMemo(
    () =>
      selectedSystemDevice
        ? systemDeviceTelemetryData.get(selectedSystemDevice)
        : undefined,
    [selectedSystemDevice, systemDeviceTelemetryData],
  );

  const fetchData = useCallback(
    (stateSetter?: RequestStateSetter): void => {
      SystemTelemetryGateway.fetchData(customerKey, stateSetter).then(
        (response) => {
          if (response.content) {
            const data = new Map(
              [...response.content].map((t) => [t.deviceId.toString(), t]),
            );
            setSystemDeviceTelemetryData(data);
          }
        },
      );
    },
    [customerKey],
  );

  const showDetails = useCallback(
    (deviceId?: string) => {
      setSelectedSystemDevice(deviceId || null);
      setSearchParams((prev) => {
        if (deviceId === undefined) {
          prev.delete(selectedSystemDeviceParamName);
          prev.delete(followControlUrlParamName);
        } else {
          prev.set(selectedSystemDeviceParamName, deviceId);
        }
        return prev;
      });
    },
    [setSearchParams],
  );

  const systemDeviceTelemetryRequestState = useDelayedRequestState(true);
  useEffect(() => {
    fetchData(systemDeviceTelemetryRequestState.setPending);
  }, [fetchData, systemDeviceTelemetryRequestState.setPending]);
  useInterval(fetchData, false);
  const systemDeviceTelemetryDataValues = useMemo(
    () => [...systemDeviceTelemetryData.values()],
    [systemDeviceTelemetryData],
  );

  return {
    selectedSystemDeviceTelemetry,
    showDetails,
    systemDeviceTelemetryData: systemDeviceTelemetryDataValues,
    systemDeviceTelemetryRequestState,
  };
};
