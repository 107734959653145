import { Filter, FilterElement, MultiSelect, MultiSelectValue } from "onway-ui";
import { ComponentProps, Dispatch, ReactElement, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useExecutionOptions } from "./ReportingIndexFilterHooks";
import { RunMode } from "./ReportingTaskConfiguration";

export type ReportingIndexFilterProps = {
  filterProps: ComponentProps<typeof Filter>;
  selectedExecutions: RunMode[];
  setSelectedExecutions: Dispatch<SetStateAction<RunMode[]>>;
  onResetExecutions: () => void;
};

export const ReportingIndexFilter = (
  props: ReportingIndexFilterProps,
): ReactElement => {
  const {
    filterProps,
    selectedExecutions,
    setSelectedExecutions,
    onResetExecutions,
  } = props;
  const { t } = useTranslation();
  const options = useExecutionOptions();

  return (
    <Filter {...filterProps}>
      <FilterElement
        label={t("execution")}
        onReset={onResetExecutions}
        options={options}
        selectedValues={selectedExecutions}
      >
        <MultiSelect
          headless
          compact
          onChange={(value: MultiSelectValue) => {
            setSelectedExecutions((value.selected ?? []) as RunMode[]);
          }}
          options={options}
          value={{ selected: selectedExecutions }}
        />
      </FilterElement>
    </Filter>
  );
};
