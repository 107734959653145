import {
  RequestState,
  RequestStateSetter,
  useDelayedRequestState,
  useInterval,
} from "frontend-util";
import { useCallback, useEffect, useState } from "react";
import { DeviceTelemetry } from "./DeviceTelemetry";
import { DeviceTelemetryGateway } from "./DeviceTelemetryGateway";
import { emptyServiceCheckWrapper, ServiceCheckWrapper } from "./ServiceCheck";

type DeviceTelemetryResult = {
  data: DeviceTelemetry | undefined;
  requestState: RequestState;
};

export const useDeviceTelemetry = (
  customerKey: string,
  deviceId: number,
): DeviceTelemetryResult => {
  const [data, setData] = useState<DeviceTelemetry>();
  const fetchData = useCallback(
    (stateSetter?: RequestStateSetter): void => {
      DeviceTelemetryGateway.fetchData(customerKey, deviceId, stateSetter).then(
        (response) => {
          if (response.content) {
            setData(response.content);
          }
          return response;
        },
      );
    },
    [customerKey, deviceId],
  );
  const dataFetchRequestState = useDelayedRequestState(true);
  useEffect(() => {
    fetchData(dataFetchRequestState.setPending);
  }, [dataFetchRequestState.setPending, fetchData]);
  useInterval(fetchData, false);
  return {
    data,
    requestState: dataFetchRequestState,
  };
};

type ServiceCheckResult = {
  checks: ServiceCheckWrapper;
  requestState: RequestState;
};

export const useServiceChecks = (
  customerKey: string,
  deviceId: number,
): ServiceCheckResult => {
  const [checks, setChecks] = useState<ServiceCheckWrapper>(
    emptyServiceCheckWrapper,
  );
  const fetchServiceChecks = useCallback(
    (stateSetter?: RequestStateSetter): void => {
      DeviceTelemetryGateway.fetchServiceChecks(
        customerKey,
        deviceId,
        stateSetter,
      ).then((response) => {
        if (response.content) {
          setChecks(response.content);
        }
        return response;
      });
    },
    [customerKey, deviceId],
  );
  const dataFetchRequestState = useDelayedRequestState(true);
  useEffect(() => {
    fetchServiceChecks(dataFetchRequestState.setPending);
  }, [dataFetchRequestState.setPending, fetchServiceChecks]);
  useInterval(fetchServiceChecks, false);
  return {
    checks,
    requestState: dataFetchRequestState,
  };
};
