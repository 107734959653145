import { BacklinkPageHeader, openSuccessAlert } from "frontend-util";
import {
  Button,
  ComponentWrapper,
  Em,
  MIN_PAGE_SIZE,
  noop,
  P,
  Page,
  Placeholder,
} from "onway-ui";
import { ReactElement, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { Paths } from "../../app/Paths";
import { useCustomerKeyParam } from "../../customer/CustomerHooks";
import { useByodAccountStore } from "./ByodAccountHooks";
import { ByodApprovedDeviceTable } from "./ByodApprovedDeviceTable";
import { ByodDeviceWizard } from "./ByodDeviceWizard";

export const ByodDeviceIndexPage = (): ReactElement => {
  const { t } = useTranslation();
  const setWizardOpenRef = useRef(noop);
  const navigate = useNavigate();
  const customerKey = useCustomerKeyParam();

  const openSuccessModal = (deviceName: string, deviceId: number) => {
    openSuccessAlert(
      <P>
        <Trans
          i18nKey="byod_device_added_msg"
          values={{ name: deviceName }}
          components={{ em: <Em /> }}
        />
      </P>,
      () =>
        navigate(
          generatePath(Paths.BYOD_DEVICE, {
            customerKey,
            id: String(deviceId),
          }),
        ),
      t,
    );
  };
  const {
    autoRefreshDisabled,
    byodAccount,
    byodSettingsInfo,
    handleUpdateResponse,
    mustAcceptAup,
    naGroup,
    pagination,
    pageLoading,
    deleteDevice,
  } = useByodAccountStore(customerKey, openSuccessModal);
  const approvedDevices = byodAccount.data
    ? byodAccount.data.devices.entities
    : [];
  const maxApprovedDevices = byodAccount.data?.maxApprovedDevices;
  const totalApprovedDevices = byodAccount.data?.totalApprovedDevices ?? 0;
  const showPagination = totalApprovedDevices > MIN_PAGE_SIZE;
  const tablePagination = showPagination ? pagination : undefined;

  const emptyAction = {
    label: t("connect_first_device"),
    onClick: () => setWizardOpenRef.current(true),
  };

  const renderPlaceholder = () => (
    <Placeholder
      action={emptyAction}
      pictogram="smartphone"
      text={t("empty_no_devices")}
    />
  );

  const headerActions = [
    <Button
      icon="plus"
      label={t("add")}
      onClick={() => setWizardOpenRef.current(true)}
      primary
    />,
  ];

  const renderDescription = () => {
    if (maxApprovedDevices) {
      return `${t("byod_devices_description_1")} ${t(
        "byod_devices_description_2",
        { count: maxApprovedDevices },
      )}`;
    }
    return t("byod_devices_description_1");
  };

  const renderTable = () => {
    return (
      <ByodApprovedDeviceTable
        approvedDevices={approvedDevices}
        onRowClick={(deviceId: number) =>
          navigate(
            generatePath(Paths.BYOD_DEVICE, {
              customerKey,
              id: String(deviceId),
            }),
          )
        }
        pagination={tablePagination}
      />
    );
  };

  return (
    <>
      <Page>
        <BacklinkPageHeader
          actions={headerActions}
          description={renderDescription()}
          title={t(Paths.BYOD_DEVICES_LABEL_KEY)}
        />
        <Page.Body loading={pageLoading} noAutoAlignment>
          {!mustAcceptAup.userCreation && approvedDevices.length
            ? renderTable()
            : renderPlaceholder()}
        </Page.Body>
      </Page>
      <ComponentWrapper setRenderChildren={setWizardOpenRef}>
        <ByodDeviceWizard
          approvedDevices={approvedDevices}
          autoRefreshDisabled={autoRefreshDisabled}
          byodSettingsInfo={byodSettingsInfo}
          customerKey={customerKey}
          deleteDevice={deleteDevice}
          fetchByodAccount={byodAccount.fetch}
          handleUpdateResponse={handleUpdateResponse}
          maxApprovedDevices={maxApprovedDevices}
          naGroup={naGroup}
          totalApprovedDevices={totalApprovedDevices}
          mustAcceptAup={
            mustAcceptAup.userCreation || mustAcceptAup.deviceApproval
          }
          pagination={tablePagination}
          setWizardOpenRef={setWizardOpenRef}
          user={byodAccount.data?.user}
        />
      </ComponentWrapper>
    </>
  );
};
