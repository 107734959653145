import {
  BacklinkPageHeader,
  LinkRow,
  openSuccessAlert,
  PaginationWrapper,
  PaginationWrapperProps,
} from "frontend-util";
import {
  Button,
  Em,
  Glue,
  noop,
  Number,
  P,
  Page,
  Placeholder,
  Table,
  TableCell,
  TableColumn,
  ToggleSwitch,
} from "onway-ui";
import {
  ChangeEvent,
  MutableRefObject,
  ReactElement,
  useRef,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { Paths } from "../app/Paths";
import { useCustomerGroupOptions } from "../group/CustomerGroupHooks";
import { Customer } from "./Customer";
import CustomerDeleteErrorModal from "./CustomerDeleteErrorModal";
import { CustomerFilter } from "./CustomerFilter";
import { useCustomers } from "./CustomerHooks";
import CustomerWizard from "./CustomerWizard";
import { useCustomerWizard } from "./CustomerWizardHooks";

const CustomerIndexPage = (): ReactElement => {
  const { t } = useTranslation();
  const [customerDeleteErrorModalOpen, setCustomerDeleteErrorModalOpen] =
    useState<boolean>(false);
  const {
    autoRefresh,
    customers,
    customerDeleteErrors,
    fetch,
    fetchRequestState,
    filtered,
    pagination,
  } = useCustomers();
  const navigate = useNavigate();
  const clearFilterRef = useRef(noop);
  const filterHasErrorsRef = useRef(false);
  const { customerGroupOptions, optionsFetchRequestState } =
    useCustomerGroupOptions();

  const handleCustomerCreated = (customer: Customer) => {
    openSuccessAlert(
      <P>
        <Trans
          i18nKey="customer_created_msg"
          values={{ name: customer.name }}
          components={{ em: <Em /> }}
        />
      </P>,
      () =>
        navigate(
          generatePath(Paths.ADMIN_CUSTOMER_OVERVIEW, {
            customerKey: customer.key,
          }),
        ),
      t,
    );
    fetch();
  };

  const { openWizard, ...wizardResult } = useCustomerWizard(
    undefined,
    handleCustomerCreated,
  );

  const emptyAction = {
    label: t("create_first_customer"),
    onClick: () => openWizard(),
  };

  const actions = [];
  actions.push(
    <Button
      icon="plus"
      label={t("create")}
      onClick={() => openWizard()}
      primary
    />,
  );
  if (customerDeleteErrors.length) {
    actions.push(
      <Button
        error
        icon="info"
        iconOnly
        label={t("customer_delete_error")}
        onClick={() => setCustomerDeleteErrorModalOpen(true)}
      />,
    );
  }

  return (
    <>
      <Page>
        <BacklinkPageHeader
          actions={actions}
          description={t("customers_description")}
          title={t("customers")}
        >
          <CustomerFilter
            clearFilterRef={clearFilterRef}
            fetchCustomers={fetch}
            filterHasErrorsRef={filterHasErrorsRef}
          />
          <CustomerDeleteErrorModal
            customerDeleteErrors={customerDeleteErrors}
            customerDeleteErrorModalOpen={customerDeleteErrorModalOpen}
            setCustomerDeleteErrorModalOpen={setCustomerDeleteErrorModalOpen}
          />
        </BacklinkPageHeader>
        <Page.Body loading={fetchRequestState.pending} noAutoAlignment>
          <CustomerIndexView
            autoRefresh={autoRefresh}
            customers={customers}
            pagination={pagination}
          />
          <NoContentDisplay
            clearFilterRef={clearFilterRef}
            customers={customers}
            emptyAction={emptyAction}
            filtered={filtered}
          />
        </Page.Body>
      </Page>
      <CustomerWizard
        customerGroupOptions={customerGroupOptions || []}
        optionsFetchRequestState={optionsFetchRequestState}
        {...wizardResult}
      />
    </>
  );
};

type CustomerIndexViewProps = {
  autoRefresh: {
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    value: boolean;
  };
  customers: Customer[];
  pagination: PaginationWrapperProps;
};

const CustomerIndexView = (
  props: CustomerIndexViewProps,
): ReactElement | null => {
  const { autoRefresh, customers, pagination } = props;
  const { t } = useTranslation();

  if (customers.length <= 0) {
    return null;
  }

  return (
    <Table
      bottomControls={<PaginationWrapper {...pagination} />}
      topControls={[
        <Glue icon="filter" key="account-match">
          <Trans
            i18nKey="match_counter"
            components={{ number: <Number /> }}
            values={{ count: customers.length }}
          />
        </Glue>,
        <ToggleSwitch
          compact
          key="refresh-toggle"
          label={t("refresh.automatically")}
          on={autoRefresh.value}
          onChange={autoRefresh.onChange}
        />,
      ]}
    >
      <Table.Columns>
        <TableColumn sortIndicator="ascending" title={t("name")} />
        <TableColumn title={t("key")} />
        <TableColumn title={t("comment")} />
      </Table.Columns>
      <Table.Rows>
        {customers
          .filter((customer) => !customer.delete)
          .map((customer) => (
            <LinkRow
              key={customer.key}
              to={generatePath(Paths.ADMIN_CUSTOMER_OVERVIEW, {
                customerKey: customer.key,
              })}
              backlinkTitle={t("customers")}
              forwardSearchQuery={false}
            >
              <TableCell>{customer.name}</TableCell>
              <TableCell>{customer.key}</TableCell>
              <TableCell>{customer.comment}</TableCell>
            </LinkRow>
          ))}
      </Table.Rows>
    </Table>
  );
};

type NoContentDisplayProps = {
  clearFilterRef: MutableRefObject<() => void>;
  customers: Customer[];
  emptyAction: any;
  filtered: boolean;
};

const NoContentDisplay = (
  props: NoContentDisplayProps,
): ReactElement | null => {
  const { clearFilterRef, customers, emptyAction, filtered } = props;
  const { t } = useTranslation();

  if (customers.length > 0) {
    return null;
  }

  if (filtered) {
    return (
      <Placeholder
        action={{
          label: t("reset_filter"),
          onClick: () => clearFilterRef.current(),
        }}
        immediate
        pictogram="filter"
        text={t("no_customer_matches_filter")}
      />
    );
  }

  return (
    <Placeholder
      action={emptyAction}
      pictogram="users"
      text={t("empty_no_customers")}
    />
  );
};

export default CustomerIndexPage;
