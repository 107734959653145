import {
  FormState,
  InputField,
  PrimitiveFieldDefinition,
  SelectField,
} from "frontend-util";
import { Function, IP, List, Option, SubBox, Variable } from "onway-ui";
import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SystemTemplateFunctionNames } from "./SystemTemplateFunctionNames";
import { SystemTemplateHttpServiceCheck } from "./SystemTemplateHttpServiceCheck";
import { SystemTemplateIcmpServiceCheck } from "./SystemTemplateIcmpServiceCheck";
import { useSystemTemplateOutletContext } from "./SystemTemplateOutletContext";
import {
  SystemTemplateVariable,
  SystemTemplateVariableType,
} from "./SystemTemplateVariable";

const useVariableOptions = (
  predicate: (variable: SystemTemplateVariable) => boolean,
): Option[] => {
  const { template } = useSystemTemplateOutletContext();
  return useMemo(
    () =>
      template.variables.filter(predicate).map((variable) => ({
        title: variable.name,
        value: variable.id,
        node: () => <Variable name={variable.name} />,
      })),
    [predicate, template.variables],
  );
};

const ipAddressPredicate = (variable: SystemTemplateVariable) =>
  variable.type === SystemTemplateVariableType.IP;
export const useIpAddressVariableOptions = (): Option[] =>
  useVariableOptions(ipAddressPredicate);

const hostPredicate = (variable: SystemTemplateVariable) =>
  variable.type !== SystemTemplateVariableType.NETWORK;
export const useHostVariableOptions = (): Option[] =>
  useVariableOptions(hostPredicate);

const ipNetworkAddressPredicate = (variable: SystemTemplateVariable) =>
  variable.type === SystemTemplateVariableType.NETWORK;
const useIpNetworkAddressVariableOptions = (): Option[] =>
  useVariableOptions(ipNetworkAddressPredicate);

const useFunctionOptions = (): Option[] => {
  const { t } = useTranslation();
  return useMemo(
    () => [
      {
        value: SystemTemplateFunctionNames.addrAt,
        title: "addrAt($network, $offset)",
        node: () => (
          <Function name="addrAt">
            <Variable name="network" />
            <Variable name="offset" />
          </Function>
        ),
        detail: {
          text: t("target_ip_address_addr_at_hint"),
        },
      },
    ],
    [t],
  );
};

const FunctionSubBox = ({
  functionName,
  functionVariableIdFieldDef,
  functionOffsetFieldDef,
  functionOptions,
  readonly,
}: {
  functionName: string | undefined;
  functionVariableIdFieldDef: PrimitiveFieldDefinition<string>;
  functionOffsetFieldDef: PrimitiveFieldDefinition<number>;
  functionOptions: Option[];
  readonly: boolean;
}): ReactElement => {
  const { t } = useTranslation();
  const ipNetworkAddressVariableOptions = useIpNetworkAddressVariableOptions();
  const selectedFunctionOption = functionOptions.find(
    (o) => o.value === functionName,
  );
  if (!selectedFunctionOption) {
    return <></>;
  }
  const fields = [];
  if (selectedFunctionOption.value === SystemTemplateFunctionNames.addrAt) {
    fields.push(
      <SelectField
        key="network"
        disabled={readonly}
        fieldDef={functionVariableIdFieldDef}
        hint={t("target_ip_address_function_network_hint")}
        label={t("network")}
        // TODO OC-668 monospace
        options={ipNetworkAddressVariableOptions}
        placeholder="$network"
      />,
      <InputField
        key="offset"
        disabled={readonly}
        fieldDef={functionOffsetFieldDef}
        hint={{
          short: t("target_ip_address_function_offset_hint"),
          detail: {
            message: (
              <>
                <Function name="addrAt">
                  {[<IP key="network">203.0.113.0/24</IP>, 8]}
                </Function>
                {" -> "}
                <IP>203.0.113.8</IP>
              </>
            ),
          },
        }}
        label={t("offset")}
        monospace
        placeholder="$offset"
        type="positive-integer"
      />,
    );
  }
  const functionTitle = selectedFunctionOption.node?.();
  return (
    <SubBox title={functionTitle}>
      <List wrapper="dl">{fields}</List>
    </SubBox>
  );
};

export const SystemTemplateServiceCheckFunctionInput = ({
  formState,
  functionNameFieldDef,
  functionVariableIdFieldDef,
  functionOffsetFieldDef,
  readonly,
}: {
  functionNameFieldDef: PrimitiveFieldDefinition<string>;
  functionVariableIdFieldDef: PrimitiveFieldDefinition<string>;
  functionOffsetFieldDef: PrimitiveFieldDefinition<number>;
  formState: FormState<
    SystemTemplateIcmpServiceCheck | SystemTemplateHttpServiceCheck
  >;
  readonly: boolean;
}): ReactElement => {
  const { t } = useTranslation();
  const functionOptions = useFunctionOptions();
  const functionName = formState.getFieldValue(functionNameFieldDef);
  return (
    <List>
      <SelectField
        disabled={readonly}
        fieldDef={functionNameFieldDef}
        options={functionOptions}
        hint={t("target_ip_address_function_hint")}
      />
      <FunctionSubBox
        functionName={functionName}
        functionVariableIdFieldDef={functionVariableIdFieldDef}
        functionOffsetFieldDef={functionOffsetFieldDef}
        functionOptions={functionOptions}
        readonly={readonly}
      />
    </List>
  );
};
