import { Products } from "frontend-util";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Paths } from "../../app/Paths";
import { ConfigurationIndexPage, useConfigurations } from "../../configuration";
import { TargetType } from "../../model";
import { MacmanConfiguration } from "./MacmanConfiguration";

export const MacmanConfigurationIndexPage = (): ReactElement => {
  const { t } = useTranslation();
  const macmanConfigurations = useConfigurations<MacmanConfiguration>(
    TargetType.MACMAN,
  );
  const productNodes = t("nodes.macman_node_lower_other");

  return (
    <ConfigurationIndexPage
      configurations={macmanConfigurations.data}
      detailPagePath={Paths.ADMIN_MACMAN_CONFIGURATION}
      fetchConfigs={macmanConfigurations.fetch}
      pageDescription={t("configurations_description", {
        product: Products.macman.name,
        productNodes,
      })}
      pageTitle={t("configurations_product", { product: Products.macman.name })}
      pictogram="macman-configuration"
      requestState={macmanConfigurations.requestState}
      type={TargetType.MACMAN}
    />
  );
};
