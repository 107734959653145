import { FC, PropsWithChildren } from "react";
import { Id } from "../../util/commonTypes";

export type InputSwitchInputProps = PropsWithChildren & {
  label: string;
  value: Id;
};

export type InputSwitchInputType = FC<InputSwitchInputProps>;

/**
 * This is an internal component so that we can make use of the
 * `<InputSwitch.Input />` pattern.
 */
const InputSwitchInput: InputSwitchInputType = (
  props: InputSwitchInputProps,
) => {
  const { children } = props;

  return <>{children}</>;
};

export default InputSwitchInput;
