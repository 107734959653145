import { BacklinkPageHeader } from "frontend-util";
import {
  BoxButton,
  Button,
  ComponentWrapper,
  Copyable,
  Description,
  Empty,
  GridBox,
  HexViewer,
  List,
  noop,
  Page,
} from "onway-ui";
import { ReactElement, useRef } from "react";
import { useTranslation } from "react-i18next";
import { RevisionPageTitle } from "../../components/RevisionPageTitle";
import { CepConfiguration } from "../model/CepConfiguration";
import {
  useCepConfigChangedModal,
  useCepConfigurationDetailsBacklink,
  useCepConfigurationOutletContext,
} from "./CepConfigurationHooks";
import { CepConfigurationKeyPairWizard } from "./CepConfigurationKeyPairWizard";

export const CepConfigurationKeyPairPage = (): ReactElement => {
  const { t } = useTranslation();
  const {
    fetchWorkingConfig: fetch,
    hasUnpublishedChanges,
    configuration,
    pageMode,
  } = useCepConfigurationOutletContext();
  const setWizardOpenRef = useRef(noop);
  const isEdit = pageMode === "edit";
  const showDirty = hasUnpublishedChanges && isEdit;
  const backLink = useCepConfigurationDetailsBacklink(configuration, isEdit);
  const { modal: submitSuccessModal, openModal: openSubmitSuccessModal } =
    useCepConfigChangedModal();

  const onSubmitSuccess = (updatedConfig: CepConfiguration) => {
    if (
      updatedConfig.bastardDaemonConfiguration?.publicKeyHex ===
      configuration.bastardDaemonConfiguration?.publicKeyHex
    ) {
      return;
    }
    const emailSubject = "PK change for customer entry point";
    const changeDetails =
      "We are about to change the private key of our customer entry point.\n\n" +
      `Affected customer entry point: ${updatedConfig.fqdn}\n\n` +
      `Public key: ${updatedConfig.bastardDaemonConfiguration?.publicKeyHex}`;
    openSubmitSuccessModal(
      t("private_key_changed"),
      emailSubject,
      changeDetails,
      t("private_key_lower"),
    );
    fetch();
  };

  const headerActions = isEdit
    ? [
        <Button
          key="edit"
          label={t("edit")}
          onClick={() => setWizardOpenRef.current(true)}
        />,
      ]
    : [];

  const KeyPairBoxButton = () =>
    isEdit ? (
      <BoxButton
        label={t("edit")}
        onClick={() => setWizardOpenRef.current(true)}
      />
    ) : null;

  const keyValue = configuration.bastardDaemonConfiguration?.publicKeyHex ?? "";

  return (
    <>
      <Page>
        <BacklinkPageHeader
          actions={headerActions}
          description={t("cep_configuration_key_pair_description")}
          dirty={showDirty}
          mode={pageMode}
          title={
            <RevisionPageTitle
              dirty={showDirty}
              pageMode={pageMode}
              title={t("key_pair")}
              revision={configuration.revision}
            />
          }
          backLink={backLink}
        />
        <Page.Body>
          <GridBox
            actions={<KeyPairBoxButton />}
            columnSpan={4}
            half
            title={t("public_key")}
          >
            <List wrapper="dl">
              <Description term={t("public_key_ed25519")}>
                <Empty as={Copyable} contentProp="value" value={keyValue}>
                  <HexViewer>{keyValue}</HexViewer>
                </Empty>
              </Description>
            </List>
          </GridBox>
        </Page.Body>
      </Page>
      <ComponentWrapper setRenderChildren={setWizardOpenRef}>
        <CepConfigurationKeyPairWizard
          cepConfiguration={configuration}
          onSubmitSuccess={onSubmitSuccess}
          openMode={"edit"}
          setWizardOpenRef={setWizardOpenRef}
        />
      </ComponentWrapper>
      {submitSuccessModal}
    </>
  );
};
