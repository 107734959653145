import { BacklinkPageHeader } from "frontend-util";
import {
  BoxButton,
  Button,
  Code,
  ComponentWrapper,
  Copyable,
  Description,
  Empty,
  GridBox,
  List,
  noop,
  Page,
} from "onway-ui";
import { ReactElement, useRef } from "react";
import { useTranslation } from "react-i18next";
import { RevisionPageTitle } from "../../components/RevisionPageTitle";
import { CepConfiguration } from "../model/CepConfiguration";
import { CepConfigurationEndpointWizard } from "./CepConfigurationEndpointWizard";
import {
  useCepConfigChangedModal,
  useCepConfigurationDetailsBacklink,
  useCepConfigurationOutletContext,
} from "./CepConfigurationHooks";

export const CepConfigurationEndpointPage = (): ReactElement => {
  const { t } = useTranslation();
  const {
    fetchWorkingConfig: fetch,
    hasUnpublishedChanges,
    configuration,
    pageMode,
  } = useCepConfigurationOutletContext();
  const setWizardOpenRef = useRef(noop);
  const isEdit = pageMode === "edit";
  const showDirty = hasUnpublishedChanges && isEdit;
  const backLink = useCepConfigurationDetailsBacklink(configuration, isEdit);
  const { modal: submitSuccessModal, openModal: openSubmitSuccessModal } =
    useCepConfigChangedModal();

  const onSubmitSuccess = (updatedConfig: CepConfiguration) => {
    if (updatedConfig.fqdn === configuration.fqdn) {
      return;
    }
    const emailSubject = "Endpoint change for customer entry point";
    const changeDetails =
      "We are about to change the FQDN of our customer entry point.\n\n" +
      `Current FQDN: ${configuration.fqdn}\n\n` +
      `New FQDN: ${updatedConfig.fqdn}`;
    openSubmitSuccessModal(
      t("endpoint_changed"),
      emailSubject,
      changeDetails,
      t("endpoint_lower"),
    );
    fetch();
  };

  const headerActions = isEdit
    ? [
        <Button
          key="edit"
          label={t("edit")}
          onClick={() => setWizardOpenRef.current(true)}
        />,
      ]
    : [];

  const EndpointBoxButton = () =>
    isEdit ? (
      <BoxButton
        label={t("edit")}
        onClick={() => setWizardOpenRef.current(true)}
      />
    ) : null;

  return (
    <>
      <Page>
        <BacklinkPageHeader
          actions={headerActions}
          description={t("cep_configuration_endpoint_description")}
          dirty={showDirty}
          mode={pageMode}
          title={
            <RevisionPageTitle
              dirty={showDirty}
              pageMode={pageMode}
              title={t("endpoint")}
              revision={configuration.revision}
            />
          }
          backLink={backLink}
        />
        <Page.Body>
          <GridBox
            actions={<EndpointBoxButton />}
            columnSpan={4}
            half
            title={t("endpoint")}
          >
            <List wrapper="dl">
              <Description term={t("fqdn")}>
                <Copyable value={configuration.fqdn}>
                  <Empty as={Code}>{configuration.fqdn}</Empty>
                </Copyable>
              </Description>
            </List>
          </GridBox>
        </Page.Body>
      </Page>
      <ComponentWrapper setRenderChildren={setWizardOpenRef}>
        <CepConfigurationEndpointWizard
          cepConfiguration={configuration}
          onSubmitSuccess={onSubmitSuccess}
          openMode={"edit"}
          setWizardOpenRef={setWizardOpenRef}
        />
      </ComponentWrapper>
      {submitSuccessModal}
    </>
  );
};
