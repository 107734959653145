import {
  BackLink,
  BacklinkPageHeader,
  FALLBACK_CERT_ID,
  MANUAL_CERT_ID,
} from "frontend-util";
import {
  BoxButton,
  Button,
  ComponentWrapper,
  Copyable,
  noop,
  P,
  Page,
  Product,
} from "onway-ui";
import { ReactElement, useRef } from "react";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import { Paths } from "../../app/Paths";
import { RevisionPageTitle } from "../../components/RevisionPageTitle";
import { CertificateSubjectBox } from "../../system/CertificateDetailPage";
import {
  useMacmanConfigurationDetailsBacklink,
  useMacmanConfigurationOutletContext,
} from "./MacmanConfigurationHooks";
import { MacmanConfigurationRadiusWizard } from "./MacmanConfigurationRadiusWizard";

export const MacmanConfigurationRadiusPage = (): ReactElement => {
  const { t } = useTranslation();
  const {
    fetchWorkingConfig: fetch,
    hasUnpublishedChanges,
    configuration: macmanConfiguration,
    pageMode,
  } = useMacmanConfigurationOutletContext();
  const setWizardOpenRef = useRef(noop);
  const isEdit = pageMode === "edit";
  const showDirty = hasUnpublishedChanges && isEdit;
  const backLink = useMacmanConfigurationDetailsBacklink(
    macmanConfiguration,
    isEdit,
  );
  const headerActions = isEdit
    ? [
        <Button
          label={t("edit")}
          onClick={() => setWizardOpenRef.current(true)}
        />,
      ]
    : [];

  const renderRadiusCertificate = () => {
    const isManual =
      MANUAL_CERT_ID === macmanConfiguration.radiusConfiguration.certificateId;
    const addition = isManual ? (
      <>
        <P>{t("managed_manually_description")}</P>
        <MacmanConfigurationRadiusManualHelp />
      </>
    ) : undefined;

    const CertDetailsButton = () => {
      if (
        macmanConfiguration.radiusConfiguration.certificateList &&
        FALLBACK_CERT_ID !==
          macmanConfiguration.radiusConfiguration.certificateList.id
      ) {
        const certDetailsPath = generatePath(Paths.ADMIN_CERTIFICATE_DETAILS, {
          id: macmanConfiguration.radiusConfiguration.certificateList.id,
        });
        return (
          <BoxButton
            label={t("details")}
            as={BackLink}
            to={certDetailsPath}
            backlinkTitle={t("radius")}
            backlinkPathEnd={false}
          />
        );
      }
      return null;
    };

    const CertEditButton = () =>
      isEdit ? (
        <BoxButton
          label={t("edit")}
          onClick={() => setWizardOpenRef.current(true)}
        />
      ) : null;

    return (
      <Page.Body>
        <CertificateSubjectBox
          t={t}
          actions={
            <>
              <CertEditButton />
              <CertDetailsButton />
            </>
          }
          columnSpan={4}
          certificate={
            macmanConfiguration.radiusConfiguration.certificateList ||
            (isManual ? t("managed_manually") : t("self_signed_snakeoil"))
          }
          idx={0}
          addition={addition}
        />
      </Page.Body>
    );
  };

  return (
    <>
      <Page>
        <BacklinkPageHeader
          actions={headerActions}
          description={t("radius_configuration_certificate")}
          dirty={showDirty}
          mode={pageMode}
          title={
            <RevisionPageTitle
              dirty={showDirty}
              pageMode={pageMode}
              title={t("radius")}
              revision={macmanConfiguration.revision}
            />
          }
          backLink={backLink}
        />
        {renderRadiusCertificate()}
      </Page>
      <ComponentWrapper setRenderChildren={setWizardOpenRef}>
        <MacmanConfigurationRadiusWizard
          macmanConfiguration={macmanConfiguration}
          onSubmitSuccess={fetch}
          openMode={"edit"}
          setWizardOpenRef={setWizardOpenRef}
        />
      </ComponentWrapper>
    </>
  );
};

/**
 * Manual help for macman radius configuration.
 */
export const MacmanConfigurationRadiusManualHelp = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <P>
      {t("managed_manually_help")}
      <br />
      <Copyable value={"/etc/freeradius/certs/server.pem"}>
        <Product
          product="macman"
          path={["etc", "freeradius", "certs", "server.pem"]}
          pathType="file"
        />
      </Copyable>
    </P>
  );
};
