import {
  createPrimitiveFieldDefinition,
  createTypeDefinition,
  TypeDefinition,
} from "frontend-util";
import { TFunction } from "i18next";
import { Option } from "onway-ui";

export enum LastSeenFilterType {
  NEVER_SEEN = "NEVER_SEEN",
  NOT_SEEN_SINCE_DURATION = "NOT_SEEN_SINCE_DURATION",
  NOT_SEEN_SINCE_DATE = "NOT_SEEN_SINCE_DATE",
  SEEN_SINCE_DURATION = "SEEN_SINCE_DURATION",
  SEEN_SINCE_DATE = "SEEN_SINCE_DATE",
}

export type LastSeenFilter = {
  lastSeenFilterType?: LastSeenFilterType;
  lastSeenDate?: string;
  lastSeenDuration?: string;
};

export const LastSeenFilterTypeDefinition: TypeDefinition<LastSeenFilter> =
  createTypeDefinition({
    lastSeenFilterType: createPrimitiveFieldDefinition<LastSeenFilterType>(),
    lastSeenDate: createPrimitiveFieldDefinition<string>(),
    lastSeenDuration: createPrimitiveFieldDefinition<string>(),
  });

/**
 * LastSeenFilterType options for select component
 */
export const getLastSeenFilterTypeOptions = (
  t: TFunction,
  entityKey: string,
): Option[] => {
  const entity: string = t(entityKey as any) as any;
  return [
    {
      value: LastSeenFilterType.NEVER_SEEN,
      title: t("never_seen"),
      detail: {
        text: t("last_seen_filter.never_seen_description", { entity }),
      },
    },
    {
      value: LastSeenFilterType.NOT_SEEN_SINCE_DURATION,
      title: t("not_seen_for"),
      detail: {
        text: t("last_seen_filter.not_seen_for_description", { entity }),
      },
    },
    {
      value: LastSeenFilterType.NOT_SEEN_SINCE_DATE,
      title: t("not_seen_since"),
      detail: {
        text: t("last_seen_filter.not_seen_since_description", { entity }),
      },
    },
    {
      value: LastSeenFilterType.SEEN_SINCE_DURATION,
      title: t("seen_since_duration"),
      detail: {
        text: t("last_seen_filter.seen_since_duration_description", { entity }),
      },
    },
    {
      value: LastSeenFilterType.SEEN_SINCE_DATE,
      title: t("seen_since"),
      detail: {
        text: t("last_seen_filter.seen_since_description", { entity }),
      },
    },
  ];
};
