import {
  FC,
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  SyntheticEvent,
  useState,
} from "react";
import { generateInteractiveClassName } from "../../util";
import { classnames } from "../../util/classNames";
import { PropsWithFeedback, PropsWithStrings } from "../../util/props";
import { useStrings } from "../../util/strings";
import ButtonGroup from "../Button/ButtonGroup";
import SimpleButton from "../Button/SimpleButton";
import Input from "../Input/Input";
import { InfoLineProps, InputDecorator } from "../InputDecorator";
import { defaultStrings, MultiSelectValue } from "./MultiSelect";
import c from "./MultiSelectAdvancedMode.module.scss";

export enum RegexOperator {
  CASE_SENSITIVE = "CASE_SENSITIVE",
  CASE_INSENSITIVE = "CASE_INSENSITIVE",
  ANTI_MATCH_CASE_SENSITIVE = "ANTI_MATCH_CASE_SENSITIVE",
  ANTI_MATCH_CASE_INSENSITIVE = "ANTI_MATCH_CASE_INSENSITIVE",
}

export type MultiSelectAdvancedModeProps = PropsWithFeedback &
  InfoLineProps &
  PropsWithStrings<typeof defaultStrings> & {
    disabled?: boolean;
    onChange: (value: MultiSelectValue) => void;
    onCheckRegex?: (
      regex: string,
      operator: RegexOperator,
    ) => Promise<string[]> | string[];
    onReset: () => void;
    value: MultiSelectValue;
  };

export type MultiSelectAdvancedModeType = FC<MultiSelectAdvancedModeProps>;

/**
 * Internal component used in multi select component for its advanced mode.
 *
 * @private
 * @package onway-ui
 */
const MultiSelectAdvancedMode: MultiSelectAdvancedModeType = (
  props: MultiSelectAdvancedModeProps,
) => {
  const {
    disabled,
    error,
    onChange,
    onCheckRegex: onCheckRegexProp,
    onReset,
    success,
    statusMessage,
    value: valueProp,
    warn,
  } = props;

  const strings = useStrings(props, defaultStrings);

  const defaultValue = {
    regex: "",
    operator: "MATCH_CASE_SENSITIVE" as RegexOperator,
  };
  const value = { ...defaultValue, ...valueProp };

  const [matchedStrings, setMatchedOptions] = useState<string[]>();
  const onCheckRegex = async (
    event: SyntheticEvent,
    regex: string,
    operator: RegexOperator,
  ) => {
    event.preventDefault();
    setMatchedOptions(
      onCheckRegexProp && (await onCheckRegexProp(regex || "", operator)),
    );
  };

  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      onCheckRegex(event, value.regex, value.operator);
    }
  };

  const onChangeRegex = (regex?: string): void => {
    if (onChange) {
      onChange({ ...value, regex });
    }
  };

  const onChangeRegexMode = (
    event: SyntheticEvent,
    regexMode: RegexOperator,
  ): void => {
    event.preventDefault();
    if (onChange) {
      onChange({ ...value, operator: regexMode });
    }
  };

  const renderRegexModeButton = (
    operator: RegexOperator,
    label: string,
    title?: string,
  ) => (
    <SimpleButton
      disabled={disabled}
      key={operator}
      label={label}
      onClick={(event: MouseEvent) => onChangeRegexMode(event, operator)}
      outlined
      primary={value.operator === operator}
      title={title || ""}
    />
  );

  const renderResult = (): ReactElement | null => {
    if (!matchedStrings) {
      return null;
    }
    return (
      <>
        <span className={c.list_title}>{strings.results}</span>
        {matchedStrings.length ? (
          <ul className={c.list}>
            {matchedStrings.map((str) => (
              <li key={str}>{str}</li>
            ))}
          </ul>
        ) : (
          <span className={c.no_matches}>{strings.no_matches_found}</span>
        )}
      </>
    );
  };

  return (
    <InputDecorator
      {...props}
      className={classnames(
        generateInteractiveClassName(c.multiselect_advanced, c, props),
      )}
    >
      <div onKeyDown={onKeyDown}>
        <Input
          optional
          onClear={onReset}
          compact
          disabled={disabled}
          error={error}
          monospace
          label={strings["regex.title"]}
          onChange={(inputValue) => onChangeRegex(inputValue)}
          placeholder={strings["regex.title"]}
          statusMessage={statusMessage}
          strings={strings}
          success={success}
          value={value.regex || ""}
          warn={warn}
        />
      </div>
      <div className={c.controls}>
        <ButtonGroup>
          {renderRegexModeButton(
            RegexOperator.CASE_SENSITIVE,
            "~",
            strings["regex.includes_case_sensitive"],
          )}
          {renderRegexModeButton(
            RegexOperator.CASE_INSENSITIVE,
            "~*",
            strings["regex.includes_case_insensitive"],
          )}
          {renderRegexModeButton(
            RegexOperator.ANTI_MATCH_CASE_SENSITIVE,
            "!~",
            strings["regex.excludes_case_sensitive"],
          )}
          {renderRegexModeButton(
            RegexOperator.ANTI_MATCH_CASE_INSENSITIVE,
            "!~*",
            strings["regex.excludes_case_insensitive"],
          )}
        </ButtonGroup>
        {onCheckRegexProp ? (
          <SimpleButton
            disabled={disabled}
            label={strings.check}
            onClick={(event: MouseEvent) =>
              onCheckRegex(event, value.regex, value.operator)
            }
          />
        ) : null}
      </div>
      {renderResult()}
    </InputDecorator>
  );
};

export default MultiSelectAdvancedMode;
